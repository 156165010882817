<template>
  <div class="Card" @click="goMovieDetail">
    <div class="Card-image">
      <van-image
        lazy-load
        class="img"
        fit="cover"
        :src="movie.img"
        alt=""
        radius="4"
      ></van-image>
    </div>
    <div class="Card-content">
      <div class="Card-title">
        {{ movie.name }} <span v-if="movie.year"> ({{ movie.year }})</span>
      </div>
      <div class="Card-length">
        <span>{{ movie.type }}</span>
        <span>{{ movie.length }}分钟</span>
      </div>
      <div class="Card-text">主演：{{ movie.actor }}</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'

export default {
  name: 'comMovieCard',
  props: {
    movie: {
      type: Object,
      default: () => {
        return {
          Id: '',
          img: '',
          title: '',
          actor: '',
          year: null,
          type: '',
          length: '',
          description: '',
          onDemand: '',
        }
      },
    },
  },
  methods: {
    // 点击点播
    playMovie(movie) {
      console.log('点击点播')
      alert('点击点播' + movie)
      this.$emit('playMovie', movie)
    },
    // 跳转到电影详情
    goMovieDetail() {
      const data = {
        movieId: this.movieId,
        movieTitle: this.movieTitle,
        movieImg: this.movieImg,
        movieScore: this.movieScore,
        moviePlayNum: this.moviePlayNum,
        movieType: this.movieType,
        movieLength: this.movieLength,
        movieDescription: this.movieDescription,
        onDemand: this.onDemand,
      }
      this.$emit('select', data)
      // console.log("跳转到电影详情");
      // Toast("跳转到电影详情"+ this.movieId);
      // this.$router.push({
      //   path: "/filmDetail",
      //   query: {
      //     movieId: this.movieId
      //   }
      // });
    },
  },
}
</script>

<style scoped lang="less">
.Card {
  position: relative;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  width: 150px;
  &-image {
    padding: 4px 8px 4px 0;
    border-radius: @border-radius-base;
    .img {
      width: 150px;
      height: 210px;
    }
  }
  &-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #363a44;
    line-height: 22px;
    font-style: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &-score {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    font-weight: 500;
    color: #b9bcbf;
    line-height: 18px;
    margin-top: -10px;
    > span {
      width: 30px;
      height: 18px;
      font-size: 14px;
      //font-family: PingFangSC-Medium, PingFang SC,sans-serif;
      font-weight: 500;
      color: #ffb137;
      line-height: 18px;
    }
  }
  &-content {
    width: 150px;
  }
  &-length {
    margin-top: 4px;
    height: 19px;
    width: 120px;
    border-radius: @border-radius-base;
    border: @border-width-base solid @color-primary;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @color-primary;
    line-height: 19px;
    > span {
      display: inline-block;
      text-align: center;
      &:first-child {
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: white;
        padding: 0 5px;
        background: @color-primary;
        flex: 1;
      }
      &:last-child {
        flex: 1.5;
        padding: 0 5px;
      }
    }
  }
  &-text {
    padding: 4px 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    height: 24px;
    color: #686b73;
    text-align: left;
    font-style: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &-btn {
    width: 32px;
    height: 32px;
    background: rgba(164, 105, 105, 0.001);
    box-shadow: 0 4px 10px 0 rgba(136, 168, 200, 0.2);
    border: 2px solid #fff;
    backdrop-filter: blur(3px);
    position: absolute;
    right: 10px;
    top: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50px;
    &-text {
      position: absolute;
      width: 50px;
      text-align: center;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #363a44;
      line-height: 17px;
      transform: translateY(180%);
    }
  }
}
</style>
