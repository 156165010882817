<!--
 * @Date: 2024-08-22 15:08:55
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-11 16:55:53
 * @FilePath: \zyt-mobile-frontend\src\views\ruralFilm\pages\nearbyCinema\index.vue
-->
<template>
  <div>
    <div >
      <!-- <van-nav-bar title="周边放映" left-arrow @click-left="$router.back()" /> -->
      <div class="tabs container">
        <van-tabs
          type="line"
          color="#1492FF"
          line-height="0px"
          line-width="0"
          title-class="tab-title"
          swipeable
          background="#EEF0F3"
          animated
        >
          <van-tab title="点播成功">
            <template #title>
              <van-button
                @click="activeIndex = 0"
                style="
                  width: 148px;
                  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.06);
                  border-radius: 6px;
                "
                block
                :type="activeIndex == 0 ? 'info' : 'default'"
                size="small"
                :color="activeIndex == 0 ? '#1492FF' : '#B3B5B9'"
                >今日放映</van-button
              >
            </template>

            <div class="film-list">
              <div
                class="film-list-item"
                v-for="(movie,index) in filmList"
                :key="index"
                @click="toDetail(movie.id)"
              >
                <film-item :movie="movie" />
              </div>
            </div>
            <van-empty description="暂无数据" v-if="filmList.length == 0" />
          </van-tab>
          <van-tab title="点播失败">
            <template #title>
              <van-button
                @click="activeIndex = 1"
                block
                style="
                  width: 148px;
                  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.06);
                  border-radius: 6px;
                "
                :color="activeIndex == 1 ? '#1492FF' : '#B3B5B9'"
                :type="activeIndex == 1 ? 'info' : 'default'"
                size="small"
                >即将放映</van-button
              >
            </template>
            <div class="film-list">
              <div
                class="film-list-item"
                v-for="(movie,index) in filmList2"
                :key="index"
                @click="toDetail(movie.id)"
              >
                <film-item :movie="movie" />
              </div>
            </div>
            <van-empty description="暂无数据" v-if="filmList2.length == 0" />
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import filmItem from '../../modules/filmItem.vue'
import {api} from '@/api/index.js'
export default {
  components: {
    filmItem,
  },
  data() {
    return {
      activeIndex: 0,
      filmList: [
      ],
      filmList2: [],
    }
  },
  mounted() {
    this.getNearbyFilmList(true).then(res=>{
      this.filmList = res.result.records.map(r=>{
        return {
          ...r,
          img: r.filmPicture,
          length: r.filmLength,
          actor: r.starrings
        }
      })
    })
    this.getNearbyFilmList(false).then(res=>{
      console.log(res,'sssss');

      this.filmList2 =  res.result.records.map(r=>{
        return {
          ...r,
          img: r.filmPicture,
          length: r.filmLength,
          actor: r.starrings
        }
      })
    })
  },
  methods: {
    // 获取周边放映影片
    getNearbyFilmList(today) {
     return api.getMoreFilmAroundList({
        showPointId:this.$store.state.userPoint.id,
        today
      })
    },
    toDetail(id) {
      this.$router.push({
        path: `/ruralFilm/nearbyCinema/filmDetail?filmId=${id}&showPointId=${this.$store.state.userPoint.id}`,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 0 12px;
}
.tabs {
  padding: 16px 12px;
}
.film-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 85vw;
  margin: 0 auto;
  overflow-y: scroll;
}
</style>
